const swiperEl = document.querySelector('swiper-container');

const swiperParams = {
    injectStyles: [
        `
            :host .swiper-button-next svg, :host .swiper-button-prev svg {
                width: 24px !important;
                height: 24px !important;
            }
            `
    ]
};

if (swiperEl != null) {
    Object.assign(swiperEl, swiperParams);

    swiperEl.initialize();
}